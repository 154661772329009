<script>
  import Fa from "svelte-fa";
  import { faEnvelope, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
  import {
    faFacebook,
    faWhatsapp,
    faInstagram,
  } from "@fortawesome/free-brands-svg-icons";

  export const socials = [
    {
      icon: faEnvelope,
      link: "mailto:Vox4Training@gmail.com",
    },
    {
      icon: faInstagram,
      link: "https://www.instagram.com/vox4training",
    },
    {
      icon: faFacebook,
      link: "https://www.facebook.com/vox4training",
    },
    {
      icon: faWhatsapp,
      link: "https://wa.me/9647752832127",
    },
    {
      icon: faMapLocationDot,
      link: "https://maps.app.goo.gl/zp5rhnsbTccwF7r99",
    },
  ];
</script>

<main>
  <img src="/vox-logo.png" alt="Vox for Training" width="309.45" height="128" />
  <h1>Vox for Training</h1>
  <p>مركز ڤوكس للتدريب و التطوير</p>

  <div class="socials">
    {#each socials as social}
      <a href={social.link} target="_blank" rel="noreferrer noopener">
        <Fa icon={social.icon} fw />
      </a>
    {/each}
  </div>
</main>

<style>
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000a11;
    color: #ffffff;
  }
  img {
    margin-bottom: 1rem;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    padding: 0;
    text-align: center;
  }
  p {
    margin: 0;
    opacity: 0.7;
    text-align: center;
  }
  .socials {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
  }
  .socials a {
    display: inline-flex;
    align-items: center;
    opacity: 0.7;
    font-size: 2rem;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
  }
  .socials a:hover {
    color: #0b9dff;
    opacity: 1;
    transform: scale(1.2);
  }

  @media (max-width: 768px) {
    main {
      padding: 0 2rem;
    }
  }
</style>
