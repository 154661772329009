<main>
  <h1>This website is under maintenance, check back soon!</h1>
  <h2>
    <a href="https://instagram.com/vox4training">Vox for Training</a> -
    <a href="/bio">Bio</a>
  </h2>
</main>

<style>
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1e182d;
    text-align: center;
  }

  h1,h2 {
    color: #ffffff;
  }

  h2 {
    margin-top: 1rem;
  }

  a {
    color: #ffffff;
    transition: opacity 300ms ease-in-out;
    text-decoration: underline;
  }

  a:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    main {
      padding: 0 2rem;
    }
  }
</style>
